<template>
    <div class="card">
        <div class="card-header border-0 pt-6 bg-white row m-0">
            <div class="d-flex p-0">
                <div class="card-title row">
                    <div class="d-flex p-0 align-items-center position-relative mb-4">
                        <span class="text-gray-500 fw-bolder text-hover-primary rounded-1 cursor-pointer me-6 fs-6" @click="router.go(-1)"><i class="fas fa-arrow-left me-2 text-gray-500 fw-bolder"></i>BACK</span>
                    </div>
                    <div class="d-flex flex-column p-0 position-relative" v-if="!loadingTable">
                        <div class="row mb-2">
                            <div class="col-4 text-gray-500 fw-bolder me-6 fs-6">Kode Request</div>
                            <div class="col text-gray-500 fw-bolder me-6 fs-6">: <span class="ms-2">{{ `${route.params.kode_request_po} (${tableData.length})` }}</span></div>
                        </div>
                        <div class="row mb-2">
                            <div class="col-4 text-gray-500 fw-bolder me-6 fs-6">Warehouse</div>
                            <div class="col text-gray-500 fw-bolder me-6 fs-6">: <span class="ms-2">{{ `${tempData.warehouse}` }}</span></div>
                        </div>
                        <div class="row mb-2">
                            <div class="col-4 text-gray-500 fw-bolder me-6 fs-6">Judul</div>
                            <div class="col text-gray-500 fw-bolder me-6 fs-6">: <span class="ms-2">{{ `${tempData.title}` }}</span></div>
                        </div>
                        <div class="row mb-2">
                            <div class="col-4 text-gray-500 fw-bolder me-6 fs-6">Status</div>
                            <div class="col text-gray-500 fw-bolder me-6 fs-6 text-uppercase">:   
                              <span class="badge badge-dark ms-2" v-if="tempData.status == 'PENDING'">{{ tempData.status }}</span>
                              <span class="badge badge-info ms-2" v-else-if="tempData.status == 'REQUEST'">{{ tempData.status }}</span>
                              <span class="badge bg-gray-500 ms-2" v-else-if="tempData.status == 'DIREVISI'">{{ tempData.status }}</span>
                              <span class="badge badge-success ms-2" v-else-if="tempData.status == 'APPROVED'">{{ tempData.status }}</span>
                              <span class="badge badge-secondary ms-2" v-else-if="tempData.status == 'DITINJAU'">{{ tempData.status }}</span>
                              <span class="badge badge-warning ms-2" v-else-if="tempData.status == 'BERMASALAH'">{{ tempData.status }}</span>
                              <span class="badge badge-danger ms-2" v-else-if="tempData.status == 'DIBATALKAN'">{{ tempData.status }}</span>
                              <span class="badge badge-danger ms-2" v-else>-</span>
                            </div>
                        </div>
                        <div class="row mb-2" v-if="validateRole(udata.role_id.toString().split(','), tempData.approval_role_id.toString().split(','))">
                            <div class="col-4 text-gray-500 fw-bolder me-6 fs-6">Approval</div>
                            <div class="col text-gray-500 fw-bolder me-6 fs-6 text-uppercase">: 
                                <span class="ms-2">{{ `${tempData.approval ? 'SUDAH APPROVE' : 'MENUNGGU APPROVAL'}` }}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card-toolbar align-items-start" style="margin-left:auto">
                    <div class="row justify-content-end gs-1" data-kt-table-toolbar="base">
                        <div class="col">
                            <router-link :to="'/supplier/edit_request/'+tempData.kode_request_po" class="btn btn-primary btn-sm" v-if="tempData.status.toUpperCase() == 'PENDING' && validateRole(udata.role_id.toString().split(','), tempData.approval_role_id.toString().split(','))">
                                <i class="fas fa-edit"></i> Edit
                            </router-link>
                            <button class="btn btn-primary btn-sm" v-if="tempData.status.toUpperCase() == 'REQUEST' && validateRole(udata.role_id.toString().split(','), tempData.approval_role_id.toString().split(','))" @click="reviewRequest()">
                                <i class="fas fa-edit"></i> Tinjau
                            </button>
                            <div>
                                <button class="btn btn-primary btn-sm" v-if="(tableData.every(o => o.checklist == 'SUDAH') && (tempData.status.toUpperCase() == 'DITINJAU' || tempData.status.toUpperCase() == 'DIREVISI')) && tempData.revision && validateRole(udata.role_id.toString().split(','), tempData.approval_role_id.toString().split(','))" @click="revisionRequest()">
                                    <i class="fas fa-edit"></i> Revisi
                                </button>
                                <button class="btn btn-danger btn-sm" v-if="tableData.every(o => o.checklist == 'BELUM') && (tempData.status.toUpperCase() == 'DITINJAU' || tempData.status.toUpperCase() == 'DIREVISI') && tempData.revision" @click="tableData.map(o => o.checklist = 'SUDAH'), revision = false">
                                    <i class="fas fa-edit"></i> Batal
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="card-body pt-0 tab-content">
            <div ref="tableRef" class="table-fixed-header">
                <table class="table table-rounded table-row-dashed cursor-pointer table-hover" :class="{'overlay': loadingTable, 'overlay-block': loadingTable}">
                    <thead>
                        <tr class="text-start fw-bolder fs-7 gs-0 text-gray-400 border-gray-200 text-uppercase">
                            <th class="w-50px">#</th>
                            <th>Kategori</th>
                            <th>Artikel</th>
                            <th>SKU</th>
                            <th>Color</th>
                            <th>Size</th>
                            <th class="text-end">Harga</th>
                            <th class="text-end">Qty Request</th>
                            <th class="text-end">Qty Final</th>
                            <th class="text-end">Total</th>
                            <th class="text-end" v-if="!validateRole(udata.role_id.toString().split(','), tempData.approval_role_id.toString().split(','))">Status</th>
                            <th class="text-end" v-else>Action</th>
                        </tr>
                    </thead>
                    <tbody class="fw-bold text-gray-600">
                        <tr v-for="(item, i) in tableData" :key="i" @click="item.check = !item.check">
                            <td class="w-50px">{{ item.index }}</td>
                            <td>{{ item.raw_category }}</td>
                            <td>{{ item.artikel }}</td>
                            <td>{{ item.sku }}</td>
                            <td>{{ item.color }}</td>
                            <td>{{ item.size }}</td>
                            <td class="text-end">{{ formatIDR(item.hpp) }}</td>
                            <td class="text-end">{{ item.qty }}</td>
                            <td class="text-end" @click.stop>
                                <div class="row justify-content-end">
                                    <input v-if="item.checklist === 'BELUM' && tempData.status == 'DITINJAU' && validateRole(udata.role_id.toString().split(','), tempData.approval_role_id.toString().split(','))" type="number" class="form-control w-100px" placeholder="Qty" v-model="item.qty_final" @change="item.qty_final ? item.check = true : item.check = false"/>
                                    <span v-else>{{ item.qty_final }}</span>
                                </div>
                            </td>
                            <td class="text-end">{{ formatIDR(item.hpp * (item.checklist == 'BELUM' ? item.qty : item.qty_final)) }}</td>
                            <td class="text-end" v-if="tempData.status == 'DITINJAU' && validateRole(udata.role_id.toString().split(','), tempData.approval_role_id.toString().split(','))">
                                <input v-if="item.checklist == 'BELUM'" class="form-check-input" type="checkbox" v-model="item.check">
                                <input v-if="item.checklist == 'SUDAH'" class="form-check-input" type="checkbox" :value="true" disabled checked>
                            </td>
                            <td class="text-end" v-else>
                                <span v-if="item.checklist === 'BELUM'"><i class="fas fa-times text-danger"></i></span>
                                <span v-else-if="item.checklist === 'SUDAH' && item.qty_final > 0"><i class="fas fa-check text-success"></i></span>
                                <span v-else-if="item.checklist === 'SUDAH' && item.qty_final == 0"><i class="fas fa-exclamation-circle text-warning"></i></span>
                                <span v-else><i class="fas fa-check text-warning"></i></span>
                            </td>
                        </tr>
                        <tr v-if="tableData.length > 0" class="fw-bolder fs-6">
                            <td colspan="7">Total</td>
                            <td class="text-end">{{ summary.qty }}</td>
                            <td class="text-end">{{ summary.qty_final }}</td>
                            <td class="text-end">{{ formatIDR(summary.hpp) }}</td>
                            <td></td>
                        </tr>
                    </tbody>
                    <div class="overlay-layer card-rounded bg-dark bg-opacity-5" v-if="loadingTable">
                        <div class="spinner-border text-primary" role="status">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                    </div>
                </table>
            </div>
            <div class="text-center my-3" v-show="tableData.length > 0">
                <el-pagination 
                    background 
                    :page-size="50"
                    layout="prev, pager, next" 
                    :page-count="pagination.total_page" 
                    @next-click="handlePageChange" 
                    @prev-click="handlePageChange" 
                    @current-change="handlePageChange" 
                    @update:current-page="pagination.page"
                />
            </div>
        </div>

        <div class="card-footer">
            <div class="row justify-content-center">
                <button
                    type="submit"
                    @click="confirmSubmit()"
                    class="btn btn-primary w-350px mb-4"
                    :data-kt-indicator="loadingButton ? 'on' : 'off'"
                     v-if="(tempData.status == 'DITINJAU' || tempData.status.toUpperCase() == 'DIREVISI') && validateRole(udata.role_id.toString().split(','), tempData.approval_role_id.toString().split(','))"
                >
                    <span class="indicator-label"> Submit Approval </span>

                    <span class="indicator-progress">
                        Please wait...
                        <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                    </span>
                </button>
                <div v-if="tempData.status == 'REQUEST'" class="text-center">
                    <i>Harap tinjau untuk melanjutkan approval</i>
                </div>
                <div v-if="(tempData.status.toUpperCase() == 'APPROVED' || tempData.status.toUpperCase() == 'DIREVISI') && listReviser.length > 0" class="text-gray-700">
                    <h6 class="text-gray-800">Riwayat revisi</h6>
                    <ul class="p-0" style="list-style: none;">
                        <li v-for="(o, i) in listReviser" :key="i">
                            <div class="row">
                                <div class="col-2">
                                    <span class="bullet bullet-dot bg-primary me-5"></span>
                                    {{ o.name }}
                                </div>
                                <span class="col-auto">{{ o.created_at }}</span>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>

        <el-dialog v-model="modal" :title="revision ? 'Revisi Approval Product' : 'Konfirmasi Approval Product'" :close-on-click-modal="true" width="50%" align-center>
            <div class="fs-6">
                Apakah yakin ingin melanjutkan {{ revision ? 'revisi' : '' }} approval {{route.params.kode_request_po}}? 
            </div>
            <template #footer>
                <div class="row justify-content-end gs-1">
                    <div class="col-auto">
                        <button class="btn btn-danger btn-sm" @click="modal = false">Cancel</button>
                    </div>
                    <div class="col-auto">
                        <button class="btn btn-primary btn-sm" @click="onSubmit()">Ok</button>
                    </div>
                </div>
            </template>
        </el-dialog>
    </div>
</template>

<script>
import { defineComponent, onBeforeMount, onMounted, ref, reactive, watchEffect, watch, computed, onUpdated } from "vue";
import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { formatIDR } from "@/helpers/functions";
//import TableLite from 'vue3-table-lite/src/components/TableLite.vue'
import Datatable from "@/components/kt-datatable/KTDatatable.vue"
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import * as Yup from "yup";
import { ErrorMessage, Field, Form } from "vee-validate";
import InnerImageZoom from 'vue-inner-image-zoom';
import { InfoFilled } from '@element-plus/icons-vue'
import { ElMessage, ElMessageBox, ElLoading, Elpagination, FormInstance, FormRules } from 'element-plus'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'

export default defineComponent({
    components: { 
        //Datatable,
        //'inner-image-zoom': InnerImageZoom,
    },
    setup() {
        const route = useRoute()
        const router = useRouter()

        const store = useStore()

        const img_url_mockup = ref(process.env.VUE_APP_IMAGE_MOCKUPS)
        const img_url_desain= ref(process.env.VUE_APP_IMAGE_DESAINS)
        const img_url_logo= ref(process.env.VUE_APP_IMAGE_LOGOS)

        const loadingTable = ref(false);
        const loadingSubmit = ref(false);
        const loadingButton = ref(false);
        const tableRef = ref(null)

        const filterActive = ref(false)
        const filter = reactive({
            tipe: null,
            search: null,
        })

        const modal = ref(false);

        const formRef = ref(null)

        const tableData = reactive([]);
        const totalRecordCount = ref(0);

        const revision = ref(false)
        const listReviser = reactive([]);

        const tempData = reactive({
            approval_role_id: '',
            status: '',
        })

        const summary = ref([])

        const udata = computed(() => {
            return store.getters.currentUser
        }) 
        
        const onFilter = async (type) => {
            filterActive.value = false
        
            loadingTable.value = true

            await getData()

            loadingTable.value = false

            Object.values(filter).every(o => o == null || o.length == 0)
                ? filterActive.value = false
                : filterActive.value = true
        }

        const resetFilter = async (type) => {
          Object.keys(filter).map(key => filter[key] = null)

          loadingTable.value = true
          filterActive.value = false

          await getData()

          loadingTable.value = false
        }

        // PAGINATION
        const pagination = ref({
            per_page: 50,
            total_page: 0,
            total_row: 0,
            page: 1,
        })

        const handlePageChange = async (page) => {
            loadingTable.value = true
            tableRef.value && (tableRef.value.scrollTop = 0) // Reset scroll position

            await getData(page)
            loadingTable.value = false
        }

        const validateRole = (arr1, arr2) => {
            let arr = arr1.filter(value => arr2.includes(value))

            // if(arr1[0] == 1 || arr1[1] == 1) {
            //     return false
            // }

            if(arr.length > 0) {
                return true
            }

            return false;
        } 

        const getData = async (page = 1) => {
            try {
                loadingTable.value = true
                await tableData.splice(0)

                await ApiService.get("supplier/request_po/" + route.params.kode_request_po + "?page=" + page)
                .then(async ({ data }) => {
                    pagination.value = data.data.pagination
                    await Object.assign(tableData, data.data.data)
                    await Object.assign(tempData, data.parent)

                    summary.value = data.parent

                    tableData.forEach(o => {
                        if(o.checklist == 'SUDAH') {
                            o.check = true
                        } else {
                            o.check = false
                        }
                    })
                })
                .catch(({ response }) => {
                    if(response.data) {
                        Swal.fire({
                            title: response.data.messages,
                            icon: "error",
                            showConfirmButton: false,
                            timer: 2000
                        });

                        router.push({name: '404'})
                    }
                });

            } catch(e) {
                console.error(e)
            }

            loadingTable.value = false
        }

        const reviewRequest = async() => {
            const loading = ElLoading.service()
            
            try {
                if(tempData.status == 'DITINJAU') {
                    throw new Error('Telah ditinjau')
                }

                await ApiService.post("supplier/review_request_po/"+route.params.kode_request_po)
                .then(({ data }) => {
                    if(data.error) {
                        throw new Error(data.messages)
                    }

                    Swal.fire({
                        title: data.messages,
                        icon: "success",
                        showConfirmButton: false,
                        timer: 2000
                    });

                    getData()
                        
                })
                .catch(({ response }) => {
                    if(response.data) {
                        Swal.fire({
                            title: response.data.messages,
                            icon: "error",
                            buttonsStyling: false,
                            showConfirmButton: false,
                            timer: 2000
                        });
                    }
                });

            } catch(e) {
                Swal.fire({
                    title: e.message,
                    icon: "error",
                    buttonsStyling: false,
                    showConfirmButton: false,
                    timer: 2000
                });
            }

            loading.close()
        }

        const revisionRequest = async() => {
            const loading = ElLoading.service()
            
            try {
                revision.value = true
                tableData.map(o => o.checklist = 'BELUM')

            } catch(e) {
                Swal.fire({
                    title: e.message,
                    icon: "error",
                    buttonsStyling: false,
                    showConfirmButton: false,
                    timer: 2000
                });
            }

            loading.close()
        }


        const onDelete = async(row) => {
            try {
                if(tempData.status != 'PENDING') {
                    throw new Error('Hapus tidak berlaku')
                }

                await ApiService.post("supplier/delete_request_po_detail/"+row.id)
                .then(({ data }) => {
                    if(data.error) {
                        throw new Error(data.messages)
                    }

                    Swal.fire({
                        title: data.messages,
                        icon: "success",
                        showConfirmButton: false,
                        timer: 2000
                    });

                    getData()
                        
                })
                .catch(({ response }) => {
                    Swal.fire({
                        title: 'Request error',
                        icon: "error",
                        buttonsStyling: false,
                        showConfirmButton: false,
                        timer: 2000
                    });
                });

            } catch(e) {
                Swal.fire({
                    title: e.message,
                    icon: "error",
                    buttonsStyling: false,
                    showConfirmButton: false,
                    timer: 2000
                });
            }
        }

        const confirmSubmit = () => {
            try {

                if(tableData.some(o => !o.check)) {
                    throw new Error('Wajib checklist')
                }
                if(tableData.some(o => o.qty_final < 0)) {
                    throw new Error('Qty tidak boleh kurang dari nol')
                }

                modal.value = true

            } catch(e) {
                Swal.fire({
                    title: e.message,
                    icon: "error",
                    showConfirmButton: false,
                    timer: 3000
                });
            }
        }

        const onSubmit = async () => {
            loadingButton.value = true
            modal.value = false

            try {
                let endpoint = 'supplier/approve_request_po/';

                if(revision.value == true) {
                    endpoint = 'supplier/revision_request_po/'
                }

                await ApiService.post(endpoint + route.params.kode_request_po, { data: tableData })
                .then(({ data }) => {
                    if(data.error) {
                        throw new Error(data.messages)
                    }

                    Swal.fire({
                        title: data.messages,
                        icon: "success",
                        showConfirmButton: false,
                        timer: 2000
                    });

                    getData()
                })
                .catch(({ response }) => {  
                    throw new Error(response.data.messages)
                });

            } catch(e) {
                Swal.fire({
                    title: e.message,
                    icon: "error",
                    showConfirmButton: false,
                    timer: 3000
                });

            }

            loadingButton.value = false
        }

        const onFinish = async(row) => {
            const loading = ElLoading.service({text: 'tunggu'})

            try {
                await ApiService.post("supplier/finish_purchase_order/"+row.kode_po)
                .then(({ data }) => {
                    if(data.error) {
                        throw new Error(data.messages)
                    }

                    Swal.fire({
                        title: data.messages,
                        icon: "success",
                        showConfirmButton: false,
                        timer: 2000
                    });

                    getData()
                        
                })
                .catch(({ response }) => {
                    Swal.fire({
                        title: 'Request error',
                        icon: "error",
                        buttonsStyling: false,
                        showConfirmButton: false,
                        timer: 2000
                    });
                });

            } catch(e) {
                Swal.fire({
                    title: e.message,
                    icon: "error",
                    buttonsStyling: false,
                    showConfirmButton: false,
                    timer: 2000
                });
            }

            loading.close()
        }

        const getRevisionLog = async() => {
            try {
                await ApiService.get('supplier/request_revision_log/' + route.params.kode_request_po)
                .then(({ data }) => {
                    if(data.error) {
                        throw new Error(data.messages)
                    }

                    Object.assign(listReviser, data.data)

                })
                .catch(({ response }) => {  
                    throw new Error(response.data.messages)
                });

            } catch(e) {
                console.error(e)
            }
        }

        onBeforeMount(() => {
            setCurrentPageBreadcrumbs(route.meta.title + ' - ' + route.params.kode_request_po, ['Supplier', route.meta.title, route.params.kode_request_po]);

            getData()
            getRevisionLog()
        })

        return {
            route, router,
            formatIDR,
            modal,
            loadingTable, loadingSubmit, loadingButton, tableRef, img_url_mockup, img_url_desain, img_url_logo,
            pagination, handlePageChange,
            onFilter, resetFilter, filter, filterActive,
            confirmSubmit, onSubmit, onFinish, summary,
            udata, validateRole, listReviser, revision,
            tableData, tempData, onDelete, reviewRequest, revisionRequest,
        }
    }
})
</script>